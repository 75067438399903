<!-- 人员排版编辑 -->
<template>
  <div class="content">
    <h2 data-v-3b01a888="" class="title">
      <div data-v-3b01a888="" class="title_icon"></div>
      人员排班
    </h2>
    <div class="personnelSchedulingEdit">
      <!-- @date-click="dateClick" -->
      <!-- @year-change="yearChange" -->
      <!-- @month-change="monthChange" -->
      <div class="operation">
        <span class="mess_mg">{{ routeData.pdaManagerName }}的排班</span>
        <el-button v-show="!isCheck || isUpdate" type="primary" @click="edit()">{{
          isEdit ? "保存" : "编辑"
        }}</el-button>
        <el-button
          @click="
            $router.push({
              path: '/scheduling',
              query: { isToEdit: 'y' },
            })
          "
          >返回</el-button
        >
        <div class="update" v-show="isEdit">
          <el-select v-model="scheduleValue" class="mess_mg">
            <el-option
              v-for="item in optionsList"
              :key="item.scheduleId"
              :label="item.scheduleName"
              :value="item.scheduleId"
            ></el-option>
          </el-select>
          <el-button @click="moreUpdate">批量修改</el-button>
        </div>
      </div>
      <!-- && queryIsNext2" -->
      <calendar
        v-if="queryIsNext1"
        height="1000px"
        ref="calendar"
        :show-term="false"
        week-title-align="center"
        :render-content="renderContent"
        @year-change="yearChange"
        @month-change="monthChange"
      />
    </div>
  </div>
</template>

<script>
import Calendar from "himmas-vue-calendar";
export default {
  name: "CalendarManagement",
  components: { Calendar },
  data() {
    return {
      routeData: {},
      scheduling: "",
      holiday: [],
      optionList: [],
      dataList: [], // 筛选之后的时间和排班
      arr: [], // 暂时存储选择的时间和排班
      isEdit: false,
      pdaManagerSchList: [], // 排班日期
      scheduleValue: "",
      classesName: "",
      demoObj: {},
      queryIsNext1: false,
      // queryIsNext2: false,
      isCheck: true,
      dateTime: "",
      dateArr: [], // 记录这页日历时间数组
      isUpdate: true,
    };
  },
  computed: {
    optionsList: function () {
      return this.optionList.filter((v) => {
        return v.state === 0 || !v.state || v.state === 2;
      });
    },
  },
  // 方法集合
  methods: {
    twoDigit: function (num) {
      return ("000" + num).slice(-2);
    },
    renderContent(h, data) {
      let self = this;
      var {
        isToday,
        isWeekend,
        isOtherMonthDay,
        year,
        day,
        month,
        // renderYear,
        // renderMonth,
        lunar,
        // weekDay,
        festival,
        term,
      } = data;

      // lunar对象中存有农历数据
      var { lunarDayChiness } = lunar;

      // 第二行展示的数据的优先级为 节日>节气>农历日
      var secondInfo = festival || term || lunarDayChiness || "";

      var dateStr = `${year}-${self.twoDigit(month)}-${self.twoDigit(day)}`;
      // var isHoliday = !!~self.holiday.indexOf(dateStr) || isWeekend;
      let checkStatus;
      if (self.holiday.indexOf(dateStr) > -1) {
        checkStatus = true;
      } else {
        checkStatus = false;
      }

      var isHoliday = checkStatus || isWeekend;
      let clickTime = year + self.twoDigit(month) + self.twoDigit(day);
      let nowTime =
        new Date().getFullYear() +
        self.twoDigit(new Date().getMonth() + 1) +
        self.twoDigit(new Date().getDate());
      let classObj = {
        "date-box": true,
        canSelect: clickTime > nowTime,
        today: isToday,
        weekend: isWeekend,
        holiday: isHoliday,
        "other-month": isOtherMonthDay,
      };
      let classStr = Object.keys(classObj)
        .map((v) => {
          return classObj[v] ? v : undefined;
        })
        .join(" ");
      // self.dateArr.push(`${year}-${self.twoDigit(month)}`);
      return (
        <div class={classStr}>
          <div class="first-info">{day}</div>
          <div class={festival ? "second-info festival" : "second-info"}>{secondInfo}</div>
          <select
            class={!self.isCheck ? "selectBox show" : "selectBox hide"}
            data-detailid={self.demoObj[clickTime] ? self.demoObj[clickTime].detailId : ""}
            onChange={(event) => {
              self.change(event, data);
            }}
            disabled={clickTime <= nowTime || !self.isEdit}
          >
            {self.optionList.map(function (item) {
              return self.selectElement(item, clickTime, nowTime);
            })}
          </select>
          <span class={self.isCheck ? "text show" : "text hide"}>
            {self.demoObj[clickTime] ? self.demoObj[clickTime].scheduleName : "休息"}
          </span>
        </div>
      );
    },
    selectElement(item, clickTime, nowTime) {
      return (
        <option
          value={item.scheduleId}
          className={
            (item.state == 0 || item.state == 2) && clickTime > nowTime
              ? "optionShow"
              : "optionHide"
          }
          selected={
            item.scheduleName ==
            (this.demoObj[clickTime] ? this.demoObj[clickTime].scheduleName : "休息")
              ? "selected"
              : ""
          }
        >
          {item.scheduleName}
        </option>
      );
    },
    edit() {
      this.isEdit = !this.isEdit;
      // this.dataList;
      if (!this.isEdit) {
        // console.log(this.dataList);
        // /acb/2.0/pdaScheduleDetail/edit/
        let nowTime = new Date().getFullYear() + this.twoDigit(new Date().getMonth() + 1);
        this.$axios
          .post(
            "/acb/2.0/pdaScheduleDetail/edit/" + this.routeData.pdaManagerId,
            {
              data: JSON.parse(JSON.stringify(this.dataList)),
            },
            {
              headers: {
                "Content-Type": "application/json;",
              },
            }
          )
          .then((res) => {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
              callback: (action) => {
                this.isCheck = true;
                this.queryScheduling(this.dateTime || nowTime);
              },
            });
          })
          .catch((err) => {
            // console.log(err);
            this.isEdit = true;
          });
      } else {
        this.isCheck = false;
      }
    },
    change(e, data) {
      // console.log(e);
      // console.log(e.target.options[e.target.options.selectedIndex].text);
      let detailId = e.currentTarget.getAttribute("data-detailid");
      // console.log(detailId);
      let workday = data.year + "-" + this.twoDigit(data.month) + "-" + this.twoDigit(data.day);
      let workMonth = data.year + this.twoDigit(data.month);
      let pdaManagerId = this.routeData.pdaManagerId;
      // detailId
      // workday
      // workMonth
      // pdaManagerId
      // scheduleId
      // scheduleName
      this.arr.unshift({
        detailId: detailId,
        workday: workday,
        workMonth: Number(workMonth),
        pdaManagerId: pdaManagerId,
        scheduleId: e.target.value,
        scheduleName: e.target.options[e.target.options.selectedIndex].text,
      });
      this.dataList = this.unique(this.arr);
    },
    moreUpdate() {
      // /acb/2.0/pdaScheduleDetail/batchEditWorkMonth/{pdaManagerId}
      let nowTime = new Date().getFullYear() + this.twoDigit(new Date().getMonth() + 1);
      this.$axios
        .post("/acb/2.0/pdaScheduleDetail/batchEditWorkMonth/" + this.routeData.pdaManagerId, {
          data: {
            scheduleId: this.scheduleValue || "-1",
            workMonth: this.dateTime || nowTime,
          },
        })
        .then((res) => {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
            callback: (action) => {
              this.isCheck = true;
              this.isEdit = false;
              this.dataList = [];
              this.queryScheduling(this.dateTime || nowTime);
            },
          });
        });
    },
    queryScheduling(date) {
      // /acb/2.0/pdaScheduleDetail/query/{pdaManagerId}
      let nowTime = new Date().getFullYear() + this.twoDigit(new Date().getMonth() + 1);
      let workMonth = date || nowTime;
      this.$axios
        .get("/acb/2.0/pdaScheduleDetail/query/" + this.routeData.pdaManagerId, {
          data: {
            workMonth,
            extraWeek: 2,
          },
        })
        .then((res) => {
          this.pdaManagerSchList = res.value;
          this.demoObj = {};
          this.pdaManagerSchList.forEach((v) => {
            this.$set(this.demoObj, v.workday.split(" ")[0].split("-").join(""), {
              scheduleName: v.scheduleName,
              detailId: v.detailId,
            });
            // this.demoObj[v.workday.split(" ")[0].split("-").join("")] = {
            //   scheduleName: v.scheduleName,
            //   detailId: v.detailId,
            // };
          });
          this.queryIsNext1 = true;
          // console.log('分析数据:' + JSON.stringify(this.demoObj))
          this.$nextTick(() => {
            // this.$refs.calendar.renderThisMonth(workMonth.slice(0, 4), workMonth.slice(4));
          });
        });
    },
    unique(arr) {
      if (!Array.isArray(arr)) {
        // console.log("type error!");
        return;
      }
      let res = [];
      let list = [];
      for (let i = 0; i < arr.length; i++) {
        if (res.indexOf(arr[i].workday) === -1) {
          res.push(arr[i].workday);
          list.push(arr[i]);
        }
      }
      return list;
    },
    queryClasses() {
      this.$axios
        .get("/acb/2.0/pdaSchedule/page", {
          data: {
            scheduleName: "",
            // state: 0,
            page: 0,
            size: 0,
            operationId: this.routeData.operationId,
          },
        })
        .then((res) => {
          res.value.list.unshift({
            scheduleId: "",
            scheduleName: "休息",
          });
          this.optionList = res.value.list;
          // this.queryIsNext2 = true;
        });
    },
    yearChange(year, month) {
      let date = year + "" + this.twoDigit(month);
      this.dateTime = date;
      this.queryScheduling(date);
    },
    monthChange(year, month) {
      let date = year + "" + this.twoDigit(month);
      this.dateTime = date;
      this.queryScheduling(date);
    },
  },
  created() {},
  mounted() {
    let data = this.$route.query;
    this.routeData = data;
    if (this.routeData.command == 1) {
      this.isEdit = true;
      this.isCheck = false;
      this.isUpdate = true;
    } else {
      this.isEdit = false;
      this.isCheck = true;
      this.isUpdate = false;
    }
    this.queryClasses();
    this.queryScheduling();
  },
};
</script>
<style lang="stylus" scoped>
.personnelSchedulingEdit>>>.el-calendar .el-calendar__body .el-calendar-day
  height 200px
  font-size 16px
.content
  background: #fff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
  .personnelSchedulingEdit
    .editClass
      .dataDay
        height 30px
  span
    color #606266
</style>
<style>
.date-box {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.first-info {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}
.second-info {
  flex: 1;
  display: flex;
  justify-content: center;
  color: #999;
  font-size: 14px;
}
.second-info.festival {
  color: #f43;
}
.sign {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: #f43;
  width: 20px;
  height: 20px;
  color: #fff;
  line-height: 20px;
  text-align: center;
}
.date-box.today {
  background: #ffcccc;
  color: #fff;
}
.date-box.today .second-info {
  color: #fff;
}
.weekend {
  background: #f6f8fa;
}
.holiday .sign {
  display: block;
}
.date-box.other-month .second-info,
.date-box.other-month .first-info {
  color: #999;
}
.canSelect:hover {
  background: #ccffff;
}
.title-box {
  font-size: 20px;
}
.selectBox {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.optionHide {
  display: none;
}
.optionShow {
  display: inline;
}
select:disabled {
  opacity: 0.5;
  border-color: #ccc;
}
.update {
  float: right;
}
.text {
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.show {
  display: inline-block;
}
.hide {
  display: none;
}
.mess_mg {
  margin-right: 10px;
}
</style>
